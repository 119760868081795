<script src="../../../components/time.js"></script>
<template>
  <div class="dataCenter">
    <div class="title">
      <div class="block">
        <span class="demonstration" style="margin-right:2vh;font-size:1.4vh"
        >选择日期</span
        >
        <el-date-picker
            v-model="value1"
            type="date"
            placeholder="选择日期"
            @change="deviceTime"
        >
        </el-date-picker>

      </div>
    </div>
    <div class="sideConter">
      <div class="treeLeft">
        <el-tree
            :data="leftList"
            :props="defaultProps"
            @node-click="handleNodeClick"
        ></el-tree>
      </div>
      <div class="echartsRight" v-loading="loading">
        <div v-if="lineState" class="echartsBox" id="echartsId"></div>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";

export default {
  data() {
    return {
      value1: new Date(),
      leftList: [],
      defaultProps: {
        children: "children",
        label: "deviceName"
      },
      lineState: true,
      deviceNumber: null,
      dateArr: [],
      loading: false,
    };
  },
  mounted() {
    this.requestDevice();
  },
  methods: {
    handleNodeClick(a) {
      this.deviceNumber = a.deviceNum;
      this.deviceData(a.deviceNum, new Date(this.value1).format("yyyy-mm-dd"));

    },
    //
    disposeEcharts(){
      let echarts = this.$echarts.init(document.getElementById("echartsId"));
      echarts.dispose();
    },
    requestDevice() {
      this.$get("/deviceManage/getDeviceInfos", {page: 1, size: 10000}).then(
          res => {
            if (res.data.state == "success") {
              this.leftList = res.data.datas.filter(item => item.deviceType !== "2");//过滤掉控制设备
              //console.log(this.leftList,'+++++++++++++++++++++++')
              this.deviceNumber = this.leftList[0].deviceNum;
              this.deviceData(
                  this.deviceNumber, new Date(this.value1).format("yyyy-mm-dd")
              );

            }
          }
      );
    },

    deviceData(deviceNumber, datetime) {
      this.loading = true;
      this.$get("/dptj/cgqqx4day", {
        deviceNum: deviceNumber,
        datetime: datetime
      }).then(res => {
        if (res.data.state = "success") {
          let datas = res.data.data;
          if (datas.length != 0) {
            const seriesData = datas.map(v => {
              if (v.sensorName != "null") {
                return {
                  type: 'line',
                  data: v.values,
                  name:v.sensorName,
                  smooth: true,
                  yAxisIndex: 0,
                  symbol: 'circle',
                  symbolSize: 6,
                  showSymbol: false,
                  hoverAnimation: true,
                }
              }

            })
            this.getEcharts(datas[0].times, seriesData)
            this.loading = false
          }
        } else {
          alert("数据加载失败，请重试")
          this.loading = false

        }
      });
    },
    getEcharts(times, seriesData){
      var chartDom = document.getElementById("echartsId");
      var myChart = echarts.init(chartDom);
      let option = {
        color: ['#429CB7', '#FAC37F', '#22C888', '#7586D9', '#3eb4ff', "#edf249"],   // 折线颜色可以自定义
        grid: {
          containLabel: true,
          left: 30,
          top: 30,
          bottom: 30,
          right: 30
        },
        legend: {
          textStyle: {
            color: '#000'
          },
          show: true
        },
        tooltip: {
          show: true,
          trigger: 'axis',
        },
        xAxis: [{
          type: 'category',
          data: times,
          axisLine: {
            lineStyle: {
              color: '#000',
              type: 'dashed'//虚线
            }
          },
          axisTick: {
            show: false
          },

        }],
        yAxis: [{
          type: 'value',
          axisTick: {
            show: false
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: '#96A9B7'
            }
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: '#96A9B7',
              type:'dashed'//虚线
            }
          }
        }],
        series: seriesData
      };

      option && myChart.setOption(option);
      window.addEventListener("resize", function () {
        myChart.resize();
      });
    },
    deviceTime(time) {
      this.deviceData(this.deviceNumber, new Date(time).format("yyyy-mm-dd"));
    },
  }
};
</script>

<style lang="less" scoped>
.dataCenter {
  width: 100%;
  height: 100%;
  background: #fff;
  border-radius: 10px;
  padding: 2vh 1vh 1vh 1vh;
  box-sizing: border-box;
}
.dataCenter {
  /deep/ .el-input__inner {
    border: 1px solid #409eff;
  }
}
.sideConter {
  display: flex;
  height: 90%;
  margin-top: 2vh;

  .treeLeft {
    width: 10vw;
    // border: 1px solid green;
  }

  .echartsRight {
    flex: 1;
    position: relative;

    .operation {
      position: absolute;
      top: -5vh;
      right: 5vh;
      z-index: 2;

      span {
        cursor: pointer;
        font-size: 1.2vh;
        padding: 3px 6px;
        box-sizing: border-box;
        border: 1px solid #409eff;
        border-radius: 6px;
        margin-left: 10px;
      }
    }

    // border: 1px solid red;
  }
}
.treeLeft /deep/ .el-tree {
  height: 80vh;
  overflow-y: auto;
}
.treeLeft /deep/ .el-tree-node__content {
  background: #eee6;
  margin-bottom: 1vh;
}
.treeLeft /deep/ .el-tree-node:focus > .el-tree-node__content {
  background-color: #8bc34a;
}
#echartsId,
#echartsId2 {
  width: 100%;
  height: 100%;
}
@media screen and (max-width: 1500px) {
  .dataCenter {
    /deep/ .el-date-editor .el-range__icon {
      line-height: 4vh;
    }

    /deep/ .el-date-editor .el-range-separator {
      line-height: 4vh;
    }

    /deep/ .el-input__inner {
      height: 5vh;
      line-height: 5vh;
    }
  }
}
</style>
